import {throttle} from "lodash";
import {useCallback, useEffect, useRef} from "react";

export function useThrottledCallback<T extends (...args: any[]) => any>(
  callback: T,
  wait: number,
  options: Parameters<typeof throttle>[2] = {},
  deps: React.DependencyList = [],
): T {
  const throttledFn = useRef<ReturnType<typeof throttle>>();

  useEffect(() => {
    throttledFn.current = throttle(callback, wait, options);

    return () => {
      throttledFn.current?.cancel();
    };
  }, [callback, wait, ...deps]);

  return useCallback(
    ((...args: any[]) => {
      return throttledFn.current?.(...args);
    }) as T,
    [throttledFn],
  );
}
