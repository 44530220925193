import {orderBy} from "lodash";

export interface Interval {
  end: number;
  start: number;
}

export function mergeIntervals(a: Interval, b: Interval): Interval {
  return {
    end: Math.max(a.end, b.end),
    start: Math.min(a.start, b.start),
  };
}

export function isMergeableWith(a: Interval, b: Interval): boolean {
  return a.start <= b.end && b.start <= a.end;
}

export type AnnotatedInterval<T> = Interval & {
  id: string;
  original: T;
};

export function mergeAllIntervals(intervals: Interval[]): Interval[] {
  if (intervals.length < 2) return intervals;

  const sorted = orderBy(intervals, ["start"], ["asc"]);
  const merged: Interval[] = [];

  for (const interval of sorted) {
    const last = merged[merged.length - 1];
    if (!last || !isMergeableWith(last, interval)) {
      merged.push(interval);
    } else {
      merged[merged.length - 1] = mergeIntervals(last, interval);
    }
  }

  return merged;
}
