import {Config} from "@co-common-libs/config";
import {
  AnniversaryType,
  Availability,
  CalendarOrdering,
  CalendarWorkHours,
  Contact,
  ContactUrl,
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  DaysAbsence,
  EmployeeGroup,
  EmployeeGroupUrl,
  HoursAbsence,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  Project,
  ProjectUrl,
  Role,
  RoutePlan,
  RoutePlanUrl,
  Task,
  Timer,
  TimerStart,
  TimerUrl,
  Unit,
  UnitUrl,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp, getUserAnniversaryMap} from "@co-common-libs/resources-utils";
import {
  dateToString,
  getMidnights,
  HALF_HOUR_MINUTES,
  HOUR_MINUTES,
  MINUTE_SECONDS,
  SECOND_MILLISECONDS,
  WEEKDAY_SATURDAY,
  WEEKDAY_SUNDAY,
  weekNumber,
} from "@co-common-libs/utils";
import {
  actions,
  AppState,
  getAnniversaryTypeArray,
  getAvailabilityArray,
  getCalendarOrderingArray,
  getCalendarWorkHoursArray,
  getContactLookup,
  getCultureLookup,
  getCustomerLookup,
  getCustomerSettings,
  getDaysAbsenceArray,
  getEmployeeGroupLookup,
  getHoursAbsenceArray,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPriceItemLookup,
  getProductLookup,
  getProjectLookup,
  getRoleArray,
  getRoutePlanLookup,
  getTaskArray,
  getTimerLookup,
  getTimerStartArray,
  getUnitLookup,
  getUpdateReady,
  getUserArray,
  getUserProfileArray,
  getUserUserProfileLookup,
  getWorkTypeLookup,
  makeQueryParameterGetter,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {matchingTextColor} from "@co-frontend-libs/utils";
import {common as commonColors, green, grey, red} from "@material-ui/core/colors";
import {
  calculateColumnPacking,
  Calendar,
  generateTimerStartMapping,
  HALF_HOUR_ROW_HEIGHT,
  HOUR_COLUMN_WIDTH,
  PARTIALLY_PLANNED_TASK_HEIGHT,
  resolveTaskRelations,
  TaskBlock,
  TaskWithRelations,
  USER_COLUMN_WIDTH,
} from "app-components";
import {
  computeIntervalsTruncated,
  tasksForDate,
  tasksOverflowingToDate,
  updateAutoReloadCallback,
} from "app-utils";
import {bind} from "bind-decorator";
import ImmutableDate from "bloody-immutable-date";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import ThumbDownIcon from "mdi-react/ThumbDownIcon";
import ThumbUpIcon from "mdi-react/ThumbUpIcon";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const WEEKDAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

const messages = defineMessages({
  noUsersWithTasks: {
    defaultMessage: "Der er ingen medarbejdere med opgaver i dag",
    id: "task-calendar.no-users-with-tasks",
  },
});

const minutesSinceMidnightFromDatetimeIsoString = (isoString: string): number => {
  const date = new Date(isoString);
  return date.getHours() * HOUR_MINUTES + date.getMinutes();
};

const calculateMinutePosition = (minutesSinceMidnight: number): number => {
  return Math.round(minutesSinceMidnight * (HALF_HOUR_ROW_HEIGHT / HALF_HOUR_MINUTES));
};

const formatSeconds = (n: number, max: number): number | string => {
  if (max <= MINUTE_SECONDS) {
    return n;
  } else {
    const minutes = Math.floor(n / MINUTE_SECONDS);
    const seconds = Math.floor(n % MINUTE_SECONDS);
    const tenSeconds = 10;
    const secondsPadding = seconds < tenSeconds ? "0" : "";
    return `${minutes}:${secondsPadding}${seconds}`;
  }
};

interface FullscreenTaskCalendarStateProps {
  anniversaryTypeArray: readonly AnniversaryType[];
  availabilityArray: readonly Availability[];
  calendarOrderingArray: readonly CalendarOrdering[];
  calendarWorkHoursArray: readonly CalendarWorkHours[];
  contactLookup: (url: ContactUrl) => Contact | undefined;
  cultureLookup: (url: CultureUrl) => Culture | undefined;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  employeeGroupLookup: (url: EmployeeGroupUrl) => EmployeeGroup | undefined;
  hoursAbsenceArray: readonly HoursAbsence[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  roleArray: readonly Role[];
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  screen: string;
  taskArray: readonly Task[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  updateReady: boolean;
  userArray: readonly User[];
  userProfileArray: readonly UserProfile[];
  userUserProfileLookup: (userURL: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface FullscreenTaskCalendarDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
}

type FullscreenTaskCalendarProps = FullscreenTaskCalendarDispatchProps &
  FullscreenTaskCalendarStateProps;

interface FullscreenTaskCalendarState {
  nowMinutes: number;
  ordering: string[] | null;
  secondsRemaining: number;
  slideShowScreen: number;
}

class FullscreenTaskCalendar extends React.Component<
  FullscreenTaskCalendarProps,
  FullscreenTaskCalendarState
> {
  static contextType = IntlContext;
  calendarWrapperRef = React.createRef<HTMLDivElement>();
  context!: React.ContextType<typeof IntlContext>;
  intervalIDs: number[] | null = null;
  state: FullscreenTaskCalendarState = {
    nowMinutes: minutesSinceMidnightFromDatetimeIsoString(new Date().toISOString()),
    ordering: null,
    secondsRemaining: this.props.customerSettings.fullscreenCalendarSlideshowSeconds,
    slideShowScreen: 0,
  };
  verticalScrollElementRef = React.createRef<HTMLDivElement>();
  componentDidMount(): void {
    this.scrollToNow(this.state.nowMinutes);
    const updateNowIntervalSeconds = 10;
    this.intervalIDs = [
      window.setInterval(this.updateNow, updateNowIntervalSeconds * SECOND_MILLISECONDS),
      window.setInterval(this.updateSecondsRemaining, SECOND_MILLISECONDS),
    ];
  }
  componentDidUpdate(
    prevProps: FullscreenTaskCalendarProps,
    _prevState: FullscreenTaskCalendarState,
  ): void {
    if (this.props.updateReady && !prevProps.updateReady) {
      updateAutoReloadCallback();
    }
  }
  componentWillUnmount(): void {
    if (this.intervalIDs) {
      for (let i = 0; i < this.intervalIDs.length; i += 1) {
        const intervalID = this.intervalIDs[i];
        clearInterval(intervalID);
      }
      this.intervalIDs = null;
    }
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      customerSettings,
      daysAbsenceArray,
      hoursAbsenceArray,
      unitLookup,
      userUserProfileLookup,
    } = this.props;

    const now = new Date();
    const today = dateToString(now);
    const {startOfDay, startOfNextDay} = getMidnights(today);
    const startOfToday = startOfDay;
    const startOfTomorrow = startOfNextDay;

    let daysAbsenceIntersectingDayList: DaysAbsence[] | undefined;
    let hoursAbsenceIntersectingDayList: HoursAbsence[] | undefined;
    const absentEmployeeURLSet = new Set<string>();
    const {registerAbsence} = customerSettings;

    if (registerAbsence) {
      daysAbsenceIntersectingDayList = _.sortBy(
        daysAbsenceArray.filter((d) => d.fromDate <= today && d.toDate >= today),
        (d) => d.fromDate + d.toDate + d.absenceType,
      );
      daysAbsenceIntersectingDayList.forEach((absence) => {
        absentEmployeeURLSet.add(absence.user);
      });
      hoursAbsenceIntersectingDayList = _.sortBy(
        hoursAbsenceArray.filter(
          (h) => h.fromTimestamp <= startOfTomorrow && h.toTimestamp >= startOfToday,
        ),
        (h) => h.fromTimestamp + h.toTimestamp + h.absenceType,
      );
      hoursAbsenceIntersectingDayList.forEach((absence) => {
        absentEmployeeURLSet.add(absence.user);
      });
    } else {
      daysAbsenceIntersectingDayList = [];
      hoursAbsenceIntersectingDayList = [];
    }

    const timerStartSeq = this.props.timerStartArray;
    let taskSeq = this.props.taskArray;
    if (customerSettings.orderDrafts) {
      taskSeq = taskSeq.filter((task) => {
        const orderURL = task.order;
        const order = orderURL && this.props.orderLookup(orderURL);
        return !orderURL || (order && !order.draft);
      });
    }

    const hourColumnWidth = HOUR_COLUMN_WIDTH;
    const userColumnWidth = USER_COLUMN_WIDTH;
    const windowWidth = window.innerWidth;

    const headerLineHeight = 24;
    const headerLines =
      (customerSettings.calendarShowEmployeeAlias ? 1 : 0) +
      (customerSettings.calendarShowEmployeeNumber ? 1 : 0) +
      (customerSettings.calendarShowEmployeeName ? 2 : 0);

    const usersHeaderHeight = Math.max(headerLines, 1) * headerLineHeight;

    const scrollbarWidth = 15;
    const scrollbarMargin =
      this.calendarWrapperRef.current &&
      this.calendarWrapperRef.current.scrollHeight > window.innerHeight - usersHeaderHeight
        ? scrollbarWidth
        : 0;
    const userColumnSpace = windowWidth - HOUR_COLUMN_WIDTH - scrollbarMargin;
    const maxColumns = Math.floor(userColumnSpace / USER_COLUMN_WIDTH);

    const selectedScreen = this.props.screen;
    let screen;
    if (selectedScreen !== "") {
      screen = (parseInt(selectedScreen) || 1) - 1;
    } else if (this.props.customerSettings.fullscreenCalendarSlideshow) {
      screen = this.state.slideShowScreen;
    }

    let ordering: string[] | null = null;
    if (!this.props.customerSettings.enableEmployeeCalendarManualOrder) {
      ordering = null;
    } else if (this.state.ordering) {
      ({ordering} = this.state);
    } else {
      const {calendarOrderingArray} = this.props;
      const activeCalendarOrdering = _.maxBy(
        calendarOrderingArray.filter((calendarOrdering) => calendarOrdering.fromDate <= today),
        (calendarOrdering) => calendarOrdering.fromDate,
      );
      if (activeCalendarOrdering && activeCalendarOrdering.ordering) {
        ordering = JSON.parse(activeCalendarOrdering.ordering);
      } else {
        ordering = null;
      }
    }

    const limit = screen == null ? null : maxColumns;
    const offset = screen == null ? null : maxColumns * screen;

    const {
      incompleteTasks,
      intersectingCompletedTasks,
      intersectingPlannedTasks,
      sortedTimerStarts,
    } = tasksForDate(
      today,
      taskSeq,
      timerStartSeq,
      this.props.roleArray,
      this.props.customerSettings.onlyManagerCreatedTasksCalendarPlanned,
    );
    let filteredIntersectingPlannedTasks = intersectingPlannedTasks;
    let tasksFromOverflow:
      | {
          overflowMinutes: number;
          task: Task;
        }[]
      | undefined;
    if (this.props.customerSettings.useCalendarWorkHours) {
      const tasksFromOverflowPresent = tasksOverflowingToDate(
        today,
        taskSeq,
        this.props.roleArray,
        this.props.calendarWorkHoursArray,
        this.props.customerSettings.onlyManagerCreatedTasksCalendarPlanned,
      );
      tasksFromOverflow = tasksFromOverflowPresent;
      filteredIntersectingPlannedTasks = filteredIntersectingPlannedTasks.filter((task) => {
        const {url} = task;
        return tasksFromOverflowPresent.every((overflowTask) => overflowTask.task.url !== url);
      });
    }

    const userAnniversaryMap = customerSettings.anniversariesEnabled
      ? getUserAnniversaryMap(
          this.props.userProfileArray,
          this.props.anniversaryTypeArray,
          new Date(today),
        )
      : null;

    const regularEmployeeURLs = new Set(
      this.props.userProfileArray.filter((p) => p.alwaysVisible).map((p) => p.user),
    );

    const getEmployeeGroupName = (user: User): string => {
      const userProfile = this.props.userProfileArray.find((p) => p.user === user.url);
      const employeeGroupURL = userProfile ? userProfile.employeeGroup : null;
      const employeeGroup =
        typeof employeeGroupURL === "string"
          ? this.props.employeeGroupLookup(employeeGroupURL)
          : employeeGroupURL;
      const employeeGroupName =
        employeeGroup && this.props.employeeGroupLookup(employeeGroup.url)?.active
          ? employeeGroup.name
          : null;
      return employeeGroupName || "";
    };
    const userRoles: {[userURL: string]: Role} = {};
    this.props.roleArray.forEach((role) => {
      userRoles[role.user] = role;
    });
    let usersWithTasksToday = this.props.userArray.filter((user) => {
      if (!user.active) {
        return false;
      }
      const role = userRoles[user.url];

      if (role && role.consultant) {
        return false;
      }
      const userURL = user.url;
      if (regularEmployeeURLs.has(userURL)) {
        return true;
      }
      if (absentEmployeeURLSet.has(userURL)) {
        return true;
      }
      if (filteredIntersectingPlannedTasks.some((task) => task.machineOperator === userURL)) {
        return true;
      }
      if (intersectingCompletedTasks.some((task) => task.machineOperator === userURL)) {
        return true;
      }
      if (
        tasksFromOverflow?.some((overflowTask) => overflowTask.task.machineOperator === userURL)
      ) {
        return true;
      }
      const lastTimerStart = _.findLast(
        sortedTimerStarts,
        (timerStart) => timerStart.employee === userURL,
      );
      if (
        lastTimerStart &&
        (getNormalisedDeviceTimestamp(lastTimerStart) > startOfToday || lastTimerStart.timer)
      )
        return true;
      if (userAnniversaryMap?.get(userURL)) {
        return true;
      }
      return false;
    });

    const showEmployeeGroupHeaders =
      customerSettings.useEmployeeGroups && customerSettings.enableEmployeeGroups && !ordering;
    let baseSort: ((user: User) => any)[] = [
      (user) => userUserProfileLookup(user.url)?.alias || user.loginIdentifier,
    ];

    if (showEmployeeGroupHeaders) {
      baseSort = [(user) => getEmployeeGroupName(user) || null, ...baseSort];
    }

    usersWithTasksToday = _.sortBy(usersWithTasksToday, baseSort);

    if (ordering) {
      const orderIndex = (user: User): number => {
        const index = ordering.indexOf(
          userUserProfileLookup(user.url)?.alias || user.loginIdentifier,
        );
        return index !== -1 ? index : ordering.length;
      };
      usersWithTasksToday = _.sortBy(usersWithTasksToday, orderIndex);
    }
    if (customerSettings.defaultTaskEmployee) {
      const defaultTaskEmployeeUrl = instanceURL("user", customerSettings.defaultTaskEmployee);
      usersWithTasksToday = _.orderBy(
        usersWithTasksToday,
        (user) => user.url !== defaultTaskEmployeeUrl,
      );
    }

    if (offset) {
      usersWithTasksToday = usersWithTasksToday.slice(offset);
    }
    if (limit) {
      usersWithTasksToday = usersWithTasksToday.slice(0, limit);
    }

    let employeeGroupHeader;
    if (!ordering && customerSettings.useEmployeeGroups && customerSettings.enableEmployeeGroups) {
      const groups = new Map<string, number>();
      usersWithTasksToday.forEach((user) => {
        const groupName = getEmployeeGroupName(user);
        groups.set(groupName, (groups.get(groupName) || 0) + 1);
      });

      employeeGroupHeader = [];
      let withBackground = true;
      groups.forEach((count, name) => {
        const style: React.CSSProperties = {
          display: "inline-block",
          textAlign: "center",
          width: userColumnWidth * count,
        };
        if (withBackground) {
          style.backgroundColor = "rgb(221, 221, 221)";
        }
        const innerStyle: React.CSSProperties = {
          height: 24,
          overflow: "hidden",
          textOverflow: "ellipsis",
        };
        withBackground = !withBackground;
        employeeGroupHeader.push(
          <div key={name} style={style}>
            <div style={innerStyle}>{name || " "}</div>
          </div>,
        );
      });
    }

    const dayUserAvailability = new Map<string, boolean | null>();
    if (
      this.props.customerSettings.availabilityWeekdays ||
      this.props.customerSettings.availabilityWeekends
    ) {
      const weekdayNumber = now.getDay();
      const weekdayIsWeekend =
        weekdayNumber === WEEKDAY_SUNDAY || weekdayNumber === WEEKDAY_SATURDAY;
      if (
        (weekdayIsWeekend && this.props.customerSettings.availabilityWeekends) ||
        (!weekdayIsWeekend && this.props.customerSettings.availabilityWeekdays)
      ) {
        const weekData = weekNumber(now);
        const selectedWeek = weekData.week;
        const selecedYear = weekData.year;
        const weekday = WEEKDAYS[now.getDay()];
        this.props.availabilityArray
          .filter(
            (availability) =>
              availability.weekNumber === selectedWeek && availability.year === selecedYear,
          )
          .forEach((value) => {
            dayUserAvailability.set(value.user, value[weekday]);
          });
      }
    }
    const taskTimerStartMapping = generateTimerStartMapping(sortedTimerStarts);

    const nowString = now.toISOString();
    const boundResolveTaskRelations = (task: Task): TaskWithRelations => {
      let taskWithComputedTimeSet = task;
      if (!task.archivable && taskTimerStartMapping) {
        const taskURL = task.url;
        const arrayForTask = taskTimerStartMapping[taskURL];
        const computedIntervals = computeIntervalsTruncated(arrayForTask || [], nowString);
        taskWithComputedTimeSet = {...task, computedTimeSet: computedIntervals};
      }
      const {
        contactLookup,
        cultureLookup,
        customerLookup,
        locationLookup,
        machineLookup,
        orderLookup,
        priceItemLookup,
        productLookup,
        projectLookup,
        timerLookup,
        workTypeLookup,
      } = this.props;
      return resolveTaskRelations(
        taskWithComputedTimeSet,
        new ImmutableDate(startOfToday),
        new ImmutableDate(startOfTomorrow),
        nowString,
        false,
        this.props.customerSettings,
        {
          contactLookup,
          cultureLookup,
          customerLookup,
          locationLookup,
          machineLookup,
          orderLookup,
          priceItemLookup,
          productLookup,
          projectLookup,
          timerLookup,
          workTypeLookup,
        },
      );
    };

    const userURLs = usersWithTasksToday.map((user) => user.url);

    const columnCountPerUser = new Map<string, number>();
    userURLs.forEach((userURL) => {
      const columns = calculateColumnPacking(
        intersectingPlannedTasks,
        intersectingCompletedTasks,
        incompleteTasks,
        userURL,
        boundResolveTaskRelations,
        this.props.customerSettings.taskOverlapWarningAfterMinutes,
      );
      const columnCount = columns.length || 1;
      columnCountPerUser.set(userURL, columnCount);
    });

    const usersHeader = usersWithTasksToday.map((user) => {
      const userURL = user.url;
      const columnCount = columnCountPerUser.get(userURL) || 1;
      const userProfile = userUserProfileLookup(userURL);

      const style: React.CSSProperties = {
        display: "inline-block",
        height: usersHeaderHeight,
        textAlign: "center",
        verticalAlign: "top",
        width: columnCount * userColumnWidth,
      };
      const availability = dayUserAvailability.get(userURL);
      let availabilityIcon;
      if (availability === true) {
        availabilityIcon = <ThumbUpIcon color={green[300]} size={12} />;
      } else if (availability === false) {
        availabilityIcon = <ThumbDownIcon color={red[300]} size={12} />;
      }

      const alias = customerSettings.calendarShowEmployeeAlias ? (
        <span>
          {(userProfile && userProfile.alias.toUpperCase()) || user.loginIdentifier.toUpperCase()}
        </span>
      ) : null;
      const employeeNumber = customerSettings.calendarShowEmployeeNumber ? (
        <span>{userProfile ? userProfile.employeeNumber : null}</span>
      ) : null;
      const name = customerSettings.calendarShowEmployeeName ? (
        <span style={{whiteSpace: "normal"}}>{userProfile ? userProfile.name : null}</span>
      ) : null;

      const aliasLine = alias ? (
        <>
          {alias} {availabilityIcon}
          {employeeNumber || name ? <br /> : null}
        </>
      ) : null;
      const employeeNumberLine = employeeNumber ? (
        <>
          {employeeNumber} {!alias ? availabilityIcon : null}
          {name ? <br /> : null}
        </>
      ) : null;
      const nameLine = name ? (
        <>
          {name} {!alias && !employeeNumber ? availabilityIcon : null}
        </>
      ) : null;

      return (
        <div key={userURL} style={style}>
          <div>
            {aliasLine}
            {employeeNumberLine}
            {nameLine}
          </div>
        </div>
      );
    });

    let noUserTasks;
    if (!usersWithTasksToday.length) {
      noUserTasks = <span>{formatMessage(messages.noUsersWithTasks)}</span>;
    }
    let userTasksWithoutTime: React.JSX.Element[] = [];
    if (filteredIntersectingPlannedTasks.length) {
      userTasksWithoutTime = usersWithTasksToday.map((user) => {
        const userURL = user.url;
        const columnCount = columnCountPerUser.get(userURL) || 1;
        const userColumnStyle = {
          display: "inline-block",
          maxWidth: userColumnWidth * (columnCount || 1),
          verticalAlign: "bottom",
          width: userColumnWidth * (columnCount || 1),
        };
        const marginWidth = 8;
        const taskList = filteredIntersectingPlannedTasks
          .filter((task) => task.machineOperator === user.url && !task.time)
          .map((task) => {
            const style: React.CSSProperties = {
              height: PARTIALLY_PLANNED_TASK_HEIGHT,
              margin: "2px 4px 2px",
              position: "relative",
              width: USER_COLUMN_WIDTH - marginWidth,
            };
            return (
              <TaskBlock
                blockType="planned"
                containerStyle={style}
                customerSettings={this.props.customerSettings}
                go={this.props.go}
                key={task.url}
                {...this.resolveTaskRelations(task)}
                unitLookup={unitLookup}
              />
            );
          });
        return (
          <div key={`without-time-${userURL}`} style={userColumnStyle}>
            {taskList}
          </div>
        );
      });
    }
    let manualOrderBlock;
    let manualOrderBlockHeight = 0;
    const manualOrderBlockHeightOrdering = 24;
    if (ordering) {
      manualOrderBlockHeight = manualOrderBlockHeightOrdering;
      const style: React.CSSProperties = {
        backgroundColor: grey[400],
        color: matchingTextColor(grey[400]),
        height: manualOrderBlockHeight,
        overflow: "hidden",
        paddingLeft: 2,
        paddingRight: 28,
        position: "relative",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      };
      manualOrderBlock = (
        <div style={style}>
          <FormattedMessage
            defaultMessage="Manuel kolonnesortering"
            id="task-calendar.label.manual-ordering"
          />
        </div>
      );
    }

    return (
      <div style={{backgroundColor: commonColors.white, height: "100%"}}>
        <div className="scrollable" style={{height: "100%", width: "100%"}}>
          <div
            style={{
              height: "100%",
              minWidth: hourColumnWidth + userURLs.length * userColumnWidth + 15,
            }}
          >
            {manualOrderBlock}
            <div
              style={{
                borderColor: "rgb(221, 221, 221)",
                borderStyle: "solid",
                borderWidth: "0px 0px 0px 1px",
                height: employeeGroupHeader ? manualOrderBlockHeightOrdering : 0,
                marginLeft: HOUR_COLUMN_WIDTH - 1 + scrollbarMargin,
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {employeeGroupHeader}
            </div>
            <div
              style={{
                borderColor: "rgb(221, 221, 221)",
                borderStyle: "solid",
                borderWidth: "0px 0px 1px 0px",
                height: usersHeaderHeight,
                marginLeft: scrollbarMargin,
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  width: HOUR_COLUMN_WIDTH - 1,
                }}
              >
                {this.props.customerSettings.fullscreenCalendarSlideshow && !this.props.screen
                  ? formatSeconds(
                      this.state.secondsRemaining,
                      this.props.customerSettings.fullscreenCalendarSlideshowSeconds,
                    )
                  : ""}
              </div>
              {usersHeader}
              {noUserTasks}
            </div>
            <div
              style={{
                height: `calc(100% - ${
                  manualOrderBlockHeightOrdering +
                  (employeeGroupHeader ? manualOrderBlockHeightOrdering : 0) +
                  manualOrderBlockHeight
                }px)`,
              }}
            >
              <div
                ref={this.verticalScrollElementRef}
                style={{
                  direction: "rtl",
                  height: "100%",
                  overflowY: "auto",
                  width: "100%",
                }}
              >
                <div style={{direction: "ltr", height: "100%", width: "100%"}}>
                  {userTasksWithoutTime.length > 0 ? (
                    <div style={{lineHeight: "16px", whiteSpace: "nowrap"}}>
                      <div
                        style={{
                          display: "inline-block",
                          width: hourColumnWidth,
                        }}
                      />
                      {userTasksWithoutTime}
                    </div>
                  ) : null}

                  <div
                    ref={this.calendarWrapperRef}
                    style={{position: "relative", whiteSpace: "nowrap"}}
                  >
                    <Calendar
                      date={today}
                      incompleteTasks={incompleteTasks}
                      intersectingCompletedTasks={intersectingCompletedTasks}
                      intersectingDaysAbsenceList={daysAbsenceIntersectingDayList}
                      intersectingHoursAbsenceList={hoursAbsenceIntersectingDayList}
                      intersectingPlannedTasks={filteredIntersectingPlannedTasks}
                      nowMinutes={this.state.nowMinutes}
                      sortedTimerStarts={sortedTimerStarts}
                      tasksFromOverflow={tasksFromOverflow}
                      userAnniversaryMap={userAnniversaryMap}
                      userURLs={userURLs}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  resolveTaskRelations(task: Task): TaskWithRelations {
    const {
      contactLookup,
      cultureLookup,
      customerLookup,
      customerSettings,
      locationLookup,
      machineLookup,
      orderLookup,
      priceItemLookup,
      productLookup,
      projectLookup,
      routePlanLookup,
      timerLookup,
      workTypeLookup,
    } = this.props;
    const now = new Date();
    return resolveTaskRelations(task, null, null, now.toISOString(), false, customerSettings, {
      contactLookup,
      cultureLookup,
      customerLookup,
      locationLookup,
      machineLookup,
      orderLookup,
      priceItemLookup,
      productLookup,
      projectLookup,
      routePlanLookup,
      timerLookup,
      workTypeLookup,
    });
  }
  scrollToNow(nowMinutes: number): void {
    const nowY = calculateMinutePosition(nowMinutes);
    const node = this.verticalScrollElementRef.current;
    if (node) {
      node.scrollTop = Math.max(0, nowY - node.clientHeight / 2);
    }
  }

  @bind
  updateNow(): void {
    const nowMinutes = minutesSinceMidnightFromDatetimeIsoString(new Date().toISOString());
    if (nowMinutes !== this.state.nowMinutes) {
      this.setState({nowMinutes});
      this.scrollToNow(nowMinutes);
    }
  }
  @bind
  updateSecondsRemaining(): void {
    let secondsRemaining = this.state.secondsRemaining - 1;
    if (secondsRemaining <= 0) {
      secondsRemaining = this.props.customerSettings.fullscreenCalendarSlideshowSeconds;
      this.updateSlideshowScreen();
    }
    this.setState({secondsRemaining});
  }
  updateSlideshowScreen(): void {
    const {customerSettings, screen: selectedScreen} = this.props;
    // FIXME: update wrt. absence...
    if (!customerSettings.fullscreenCalendarSlideshow) {
      return;
    }

    if (selectedScreen === "") {
      const windowWidth = window.innerWidth;
      const scrollbarWidth = 15;
      const userColumnSpace = windowWidth - HOUR_COLUMN_WIDTH - scrollbarWidth;
      const maxColumns = Math.floor(userColumnSpace / USER_COLUMN_WIDTH);

      const now = new Date();
      const today = dateToString(now);
      const {startOfDay, startOfNextDay} = getMidnights(today);
      const startOfToday = startOfDay;
      const startOfTomorrow = startOfNextDay;
      const {daysAbsenceArray, hoursAbsenceArray} = this.props;

      const timerStartSeq = this.props.timerStartArray;
      let taskSeq = this.props.taskArray;
      if (customerSettings.orderDrafts) {
        taskSeq = taskSeq.filter((task) => {
          const orderURL = task.order;
          const order = orderURL && this.props.orderLookup(orderURL);
          return !orderURL || (order && !order.draft);
        });
      }
      const {
        intersectingCompletedTasks,
        // incompleteTasks,
        intersectingPlannedTasks,
        sortedTimerStarts,
      } = tasksForDate(
        today,
        taskSeq,
        timerStartSeq,
        this.props.roleArray,
        this.props.customerSettings.onlyManagerCreatedTasksCalendarPlanned,
      );
      let filteredIntersectingPlannedTasks = intersectingPlannedTasks;
      let tasksFromOverflow:
        | {
            overflowMinutes: number;
            task: Task;
          }[]
        | undefined;
      if (this.props.customerSettings.useCalendarWorkHours) {
        const tasksFromOverflowPresent = tasksOverflowingToDate(
          today,
          taskSeq,
          this.props.roleArray,
          this.props.calendarWorkHoursArray,
          this.props.customerSettings.onlyManagerCreatedTasksCalendarPlanned,
        );
        tasksFromOverflow = tasksFromOverflowPresent;
        filteredIntersectingPlannedTasks = filteredIntersectingPlannedTasks.filter((task) => {
          const {url} = task;
          return tasksFromOverflowPresent.every((overflowTask) => overflowTask.task.url !== url);
        });
      }

      const absentEmployeeURLSet = new Set<string>();
      const {registerAbsence} = this.props.customerSettings;

      if (registerAbsence) {
        const daysAbsenceIntersectingDayList = _.sortBy(
          daysAbsenceArray.filter((d) => d.fromDate <= today && d.toDate >= today),
          (d) => d.fromDate + d.toDate + d.absenceType,
        );
        daysAbsenceIntersectingDayList.forEach((absence) => {
          absentEmployeeURLSet.add(absence.user);
        });
        const hoursAbsenceIntersectingDayList = _.sortBy(
          hoursAbsenceArray.filter(
            (h) => h.fromTimestamp <= startOfTomorrow && h.toTimestamp >= startOfToday,
          ),
          (h) => h.fromTimestamp + h.toTimestamp + h.absenceType,
        );
        hoursAbsenceIntersectingDayList.forEach((absence) => {
          absentEmployeeURLSet.add(absence.user);
        });
      }

      const regularEmployeeURLs = new Set(
        this.props.userProfileArray.filter((p) => p.alwaysVisible).map((p) => p.user),
      );

      const userRoles: {[userURL: string]: Role} = {};
      this.props.roleArray.forEach((role) => {
        userRoles[role.user] = role;
      });
      const allUsersWithTasksToday = this.props.userArray.filter((user) => {
        const role = userRoles[user.url];
        if (role && role.consultant) {
          return false;
        }
        if (user.active && regularEmployeeURLs.has(user.url)) {
          return true;
        }
        const userURL = user.url;
        if (absentEmployeeURLSet.has(userURL)) {
          return true;
        }
        if (filteredIntersectingPlannedTasks.some((task) => task.machineOperator === userURL)) {
          // user has planned task
          return true;
        }
        if (intersectingCompletedTasks.some((task) => task.machineOperator === userURL)) {
          // user has completed task
          return true;
        }
        if (
          tasksFromOverflow &&
          tasksFromOverflow.some((overflowTask) => overflowTask.task.machineOperator === userURL)
        ) {
          return true;
        }
        const lastTimerStart = _.findLast(
          sortedTimerStarts,
          (timerStart) => timerStart.employee === userURL,
        );
        if (
          lastTimerStart &&
          (getNormalisedDeviceTimestamp(lastTimerStart) > startOfToday || lastTimerStart.timer)
        ) {
          // user has some timer registration
          return true;
        }
        // NOTE: We *could* handle manually entered times/corrections on
        // incomplete tasks --- but probably not worth the complexity/computation
        // cost...
        return false;
      });

      const screenCount = Math.ceil(allUsersWithTasksToday.length / maxColumns);
      this.setState({
        slideShowScreen: (this.state.slideShowScreen + 1) % screenCount,
      });
    }
  }
}

const ConnectedFullscreenTaskCalendar = connect<
  FullscreenTaskCalendarStateProps,
  FullscreenTaskCalendarDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, FullscreenTaskCalendarStateProps>({
    anniversaryTypeArray: getAnniversaryTypeArray,
    availabilityArray: getAvailabilityArray,
    calendarOrderingArray: getCalendarOrderingArray,
    calendarWorkHoursArray: getCalendarWorkHoursArray,
    contactLookup: getContactLookup,
    cultureLookup: getCultureLookup,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    daysAbsenceArray: getDaysAbsenceArray,
    employeeGroupLookup: getEmployeeGroupLookup,
    hoursAbsenceArray: getHoursAbsenceArray,
    locationLookup: getLocationLookup,
    machineLookup: getMachineLookup,
    orderLookup: getOrderLookup,
    priceItemLookup: getPriceItemLookup,
    productLookup: getProductLookup,
    projectLookup: getProjectLookup,
    roleArray: getRoleArray,
    routePlanLookup: getRoutePlanLookup,
    screen: makeQueryParameterGetter("screen", ""),
    taskArray: getTaskArray,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    unitLookup: getUnitLookup,
    updateReady: getUpdateReady,
    userArray: getUserArray,
    userProfileArray: getUserProfileArray,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    go: actions.go,
    putQueryKey: actions.putQueryKey,
  },
)(FullscreenTaskCalendar);

export {ConnectedFullscreenTaskCalendar as FullscreenTaskCalendar};
