import {frontendVersion} from "@co-common-libs/frontend-version";
import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {
  actions,
  getCurrentRole,
  getCustomerSettings,
  getUpdateReady,
} from "@co-frontend-libs/redux";
import {LinearProgress} from "@material-ui/core";
import {ClearAppButton} from "app-components";
import {
  computePersistedQueries,
  updateAutoReloadCallback,
  useReconnectEffect,
  useThrottledCallback,
} from "app-utils";
import React, {useEffect} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useIsOnline} from "react-use-is-online";

function FetchingUserData(): React.JSX.Element {
  const updateReady = useSelector(getUpdateReady);
  const {isOnline} = useIsOnline();
  const dispatch = useDispatch();
  const customerSettings = useSelector(getCustomerSettings);
  const currentRole = useSelector(getCurrentRole);

  const GIVE_UP_WAITING_AND_REREQUEST_QUERIES_TIMEOUT_SECONDS = 30;

  const requestQueriesThrottled = useThrottledCallback(
    () => {
      const queries = computePersistedQueries(customerSettings, currentRole || undefined);
      dispatch(actions.clearPersistentQueries(queries));
      dispatch(actions.persistedQueriesRequested(queries));
    },
    GIVE_UP_WAITING_AND_REREQUEST_QUERIES_TIMEOUT_SECONDS * SECOND_MILLISECONDS,
    {
      leading: false,
      trailing: true,
    },
    [dispatch, customerSettings, currentRole],
  );

  useReconnectEffect(() => {
    requestQueriesThrottled();
  });

  useEffect(() => {
    if (updateReady) {
      updateAutoReloadCallback();
    }
  }, [updateReady]);

  return (
    <div style={{alignItems: "center", display: "flex", height: "100vh"}}>
      <div style={{textAlign: "center", width: "100%"}}>
        {isOnline ? (
          <>
            <FormattedMessage
              defaultMessage="Henter initielle data"
              id="fetching-user-data.label.fetching-initial-data"
            />
            <LinearProgress variant="indeterminate" />
          </>
        ) : (
          <div style={{color: "red"}}>
            <FormattedMessage
              defaultMessage="Forbindelse mistet. Forsøger at genoprette... "
              id="fetching-user-data.label.connection-lost"
            />
          </div>
        )}
        <br />
        <span style={{fontSize: 12, margin: 16, opacity: 0.4}}>v. {frontendVersion}</span>
      </div>
      <div
        style={{
          bottom: 10,
          left: 10,
          position: "fixed",
        }}
      >
        <ClearAppButton />
      </div>
    </div>
  );
}

export default FetchingUserData;
