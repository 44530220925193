import {AnnotatedInterval, mergeAllIntervals} from "./interval";

export type OverlapRule<T> = (a: AnnotatedInterval<T>, b: AnnotatedInterval<T>) => boolean;
export type MergeRule<T> = (intervals: AnnotatedInterval<T>[]) => AnnotatedInterval<T>[];
export type GroupByRule<T> = (intervals: AnnotatedInterval<T>[]) => AnnotatedInterval<T>[][];
export type UnpackRule<T> = (intervals: AnnotatedInterval<T>[]) => T[];

export interface Rules<T> {
  groupByRule: GroupByRule<T>;
  mergeRule: MergeRule<T>;
  overlapRule: OverlapRule<T>;
  unpackRule: UnpackRule<T>;
}

function defaultOverlapRule<T>(_a: AnnotatedInterval<T>, _b: AnnotatedInterval<T>): boolean {
  return false; // Never allow overlap by default
}

function defaultMergeRule<T>(intervals: AnnotatedInterval<T>[]): AnnotatedInterval<T>[] {
  const merged = mergeAllIntervals(intervals);
  return merged.map((mi) => {
    const original =
      intervals.find((interval) => interval.start <= mi.start && interval.end >= mi.end) ||
      intervals[0];
    return {
      end: mi.end,
      id: original.id,
      original: original.original,
      start: mi.start,
    };
  });
}

function defaultGroupByRule<T>(intervals: AnnotatedInterval<T>[]): AnnotatedInterval<T>[][] {
  const grouped: AnnotatedInterval<T>[][] = [];
  const groupMap = new Map<string, AnnotatedInterval<T>[]>();

  for (const interval of intervals) {
    if (interval.id === undefined) {
      grouped.push([interval]);
    } else {
      if (!groupMap.has(interval.id)) {
        groupMap.set(interval.id, []);
      }
      groupMap.get(interval.id)?.push(interval);
    }
  }

  return [...grouped, ...groupMap.values()];
}

function defaultUnpackRule<T>(intervals: AnnotatedInterval<T>[]): T[] {
  return intervals
    .map((interval) => interval.original)
    .filter((value): value is T => value !== undefined);
}

function canListsCoexist<T>(
  listA: AnnotatedInterval<T>[],
  listB: AnnotatedInterval<T>[],
  overlapRule: OverlapRule<T>,
): boolean {
  const hasTimeOverlap = listA.some((a) => listB.some((b) => a.start < b.end && a.end > b.start));

  if (!hasTimeOverlap) {
    return true;
  }

  return listA.every((a) =>
    listB.every((b) => overlapRule(a, b) || a.start >= b.end || a.end <= b.start),
  );
}

export function packColumns<T>(
  intervals: AnnotatedInterval<T>[],
  rules?: Partial<Omit<Rules<T>, "unpackRule">>,
): AnnotatedInterval<T>[][] {
  const {
    groupByRule = defaultGroupByRule,
    mergeRule = defaultMergeRule,
    overlapRule = defaultOverlapRule,
  } = rules || {};

  const groupedIntervals = groupByRule(intervals);
  const mergedGroupedIntervals = groupedIntervals.map((group) => mergeRule(group));
  const resultingColumns: AnnotatedInterval<T>[][] = [];

  for (const currentList of mergedGroupedIntervals) {
    let placed = false;
    for (const column of resultingColumns) {
      if (canListsCoexist(column, currentList, overlapRule)) {
        column.push(...currentList);
        placed = true;
        break;
      }
    }
    if (!placed) {
      resultingColumns.push([...currentList]);
    }
  }
  return resultingColumns;
}

export function getColumns<T>(tasks: AnnotatedInterval<T>[], rules?: Partial<Rules<T>>): T[][] {
  const {unpackRule = defaultUnpackRule, ...packRules} = rules || {};
  const packedColumns = packColumns(tasks, packRules);
  return packedColumns.map(unpackRule);
}

export function convertToAnnotatedIntervals<T>(
  generic: T,
  mapFn: (task: T) => {end: number; id: string; start: number}[],
): AnnotatedInterval<T>[] {
  return mapFn(generic).map(({end, id, start}) => ({
    end,
    id,
    original: generic,
    start,
  }));
}
