import type {Writable} from "ts-essentials";
import {Config} from "@co-common-libs/config";
import {
  AnniversaryType,
  CalendarWorkHours,
  Contact,
  ContactUrl,
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  DaysAbsence,
  DaysAbsenceUrl,
  emptyTask,
  FieldUse,
  HoursAbsence,
  HoursAbsenceUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  MachineUse,
  Order,
  OrderUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  Project,
  ProjectUrl,
  Role,
  RoutePlan,
  RoutePlanUrl,
  Task,
  TaskPhoto,
  Timer,
  TimerStart,
  TimerUrl,
  Unit,
  UnitUrl,
  urlToId,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {dateToString, notUndefined} from "@co-common-libs/utils";
import {
  ConnectedCultureDialog,
  ConnectedCustomerDialog,
  ConnectedInternalWorkTypeDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  AppState,
  getCalendarWorkHoursArray,
  getContactLookup,
  getCultureLookup,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getEmployeeGroupLookup,
  getExtraHalfHolidaysPerRemunerationGroup,
  getExtraHolidaysPerRemunerationGroup,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getProductLookup,
  getProjectLookup,
  getPunchInOutPeriodsPerEmployee,
  getRoutePlanLookup,
  getTaskPhotoArray,
  getTimerLookup,
  getUnitLookup,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
  PathTemplate,
  PunchWorkPeriod,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {Menu, MenuItem, MenuProps} from "@material-ui/core";
import {useMachine} from "@xstate/react/lib/fsm";
import {
  computeDepartment,
  computeIntervalsTruncated,
  createOrder,
  dateFromDateAndTime,
  findOverlappingPunchInOutPeriodsPart,
  getExtraHolidaysForUser,
} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import {format} from "date-fns";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {connect, useDispatch, useSelector} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {CustomerTaskCreationDisplay} from "../wizards/customer-task-creation/customer-task-creation-display";
import {customerTaskCreationStateMachine} from "../wizards/customer-task-creation/customer-task-creation-state-machine";
import {HOUR_COLUMN_WIDTH, USER_COLUMN_WIDTH} from "./constants";
import {HoursColumn} from "./hours-column";
import {NowRuler} from "./now-ruler";
import {UserColumn} from "./user-column";
import {
  calculateColumnTaskPacking,
  generateTimerStartMapping,
  resolveTaskRelations,
  TaskWithRelations,
} from "./utils";

interface CalendarStateProps {
  calendarWorkHoursArray: readonly CalendarWorkHours[];
  contactLookup: (url: ContactUrl) => Contact | undefined;
  cultureLookup: (url: CultureUrl) => Culture | undefined;
  currentRole: Role | null;
  currentUserURL: string | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  extraHalfHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined;
  extraHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined;
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  punchInOutPeriodsPerEmployee: ReadonlyMap<string, readonly PunchWorkPeriod[]>;
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  taskPhotoArray: readonly TaskPhoto[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface CalendarDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
}

interface CalendarOwnProps {
  date?: string | undefined;
  dateTransitionMark?: boolean;
  fromTimestamp?: ImmutableDate | undefined;
  hideHoursColumn?: boolean;
  incompleteTasks: readonly Task[];
  intersectingCompletedTasks: readonly Task[];
  intersectingDaysAbsenceList?: readonly DaysAbsence[] | undefined;
  intersectingHoursAbsenceList?: readonly HoursAbsence[] | undefined;
  intersectingPlannedTasks: readonly Task[];
  nowMinutes?: number | undefined;
  onAbsenceClick?: (url: DaysAbsenceUrl | HoursAbsenceUrl) => void;
  onRequestCloseTaskInfo?: (() => void) | undefined;
  onRequestTaskInfo?: ((position: {x: number; y: number}, task: Task) => void) | undefined;
  singleDateFocus?: boolean | undefined;
  sortedTimerStarts?: readonly TimerStart[] | undefined;
  tasksFromOverflow?: readonly {overflowMinutes: number; task: Task}[] | undefined;
  toTimestamp?: ImmutableDate | undefined;
  userAnniversaryMap?: ReadonlyMap<string, AnniversaryType[]> | null;
  userURLs: readonly UserUrl[];
}

type CalendarProps = CalendarDispatchProps & CalendarOwnProps & CalendarStateProps;

const Calendar = React.memo(function Calendar(props: CalendarProps): React.JSX.Element {
  const {
    calendarWorkHoursArray,
    contactLookup,
    cultureLookup,
    customerLookup,
    date: dateFromProps,
    dateTransitionMark = false,
    extraHalfHolidaysPerRemunerationGroup,
    extraHolidaysPerRemunerationGroup,
    incompleteTasks,
    intersectingCompletedTasks,
    intersectingDaysAbsenceList = [],
    intersectingHoursAbsenceList = [],
    intersectingPlannedTasks,
    locationLookup,
    machineLookup,
    orderLookup,
    priceItemLookup,
    productLookup,
    projectLookup,
    routePlanLookup,
    singleDateFocus,
    sortedTimerStarts,
    tasksFromOverflow,
    timerLookup,
    unitLookup,
    userAnniversaryMap,
    userURLs,
    userUserProfileLookup,
    workTypeLookup,
  } = props;

  let {fromTimestamp, toTimestamp} = props;
  console.assert((fromTimestamp && toTimestamp) || dateFromProps);
  const {showPhotoOnCalendar} = props.customerSettings;
  const tasksWithPhotos = showPhotoOnCalendar
    ? new Set(props.taskPhotoArray.map((instance) => instance.task))
    : new Set();

  if (!(fromTimestamp && toTimestamp)) {
    const [year, month, day] = (dateFromProps as string).split("-");
    fromTimestamp = new ImmutableDate(parseInt(year), parseInt(month) - 1, parseInt(day));
    toTimestamp = fromTimestamp.setDate(fromTimestamp.getDate() + 1);
  }

  const taskTimerStartMapping = sortedTimerStarts
    ? generateTimerStartMapping(sortedTimerStarts)
    : null;

  const now = new Date();
  const nowString = now.toISOString();
  const boundResolveTaskRelations = (task: Task): TaskWithRelations => {
    let taskWithComputedTimeSet = task;
    if (!task.archivable && taskTimerStartMapping) {
      const taskURL = task.url;
      const arrayForTask = taskTimerStartMapping[taskURL];
      const computedIntervals = computeIntervalsTruncated(arrayForTask || [], now.toISOString());
      taskWithComputedTimeSet = {
        ...task,
        computedTimeSet: computedIntervals,
      };
    }
    console.assert(fromTimestamp);
    console.assert(toTimestamp);
    return resolveTaskRelations(
      taskWithComputedTimeSet,
      fromTimestamp as ImmutableDate,
      toTimestamp as ImmutableDate,
      nowString,
      showPhotoOnCalendar && tasksWithPhotos.has(task.url),
      props.customerSettings,
      {
        contactLookup,
        cultureLookup,
        customerLookup,
        locationLookup,
        machineLookup,
        orderLookup,
        priceItemLookup,
        productLookup,
        projectLookup,
        routePlanLookup,
        timerLookup,
        workTypeLookup,
      },
    );
  };

  const customerSettings = useSelector(getCustomerSettings);
  const currentUserUrl = useSelector(getCurrentUserURL);

  const [taskCreation, setTaskCreation] = useState<{
    duration: number | null;
    fromTime: Date;
    userUrl: UserUrl;
  } | null>(null);

  const [taskCreationPopup, setTaskCreationPopup] = useState<{
    anchorPoint: {left: number; top: number};
    clearDragStartPointEndPoint: () => void;
  } | null>(null);

  const handleTaskCreationPopupClose = useCallback(() => {
    if (taskCreationPopup) {
      taskCreationPopup.clearDragStartPointEndPoint();
    }
    setTaskCreationPopup(null);
  }, [taskCreationPopup]);

  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const setCustomerDialogOpenFalse = useCallWithFalse(setCustomerDialogOpen);

  const [internalWorkTypeDialogOpen, setInternalWorkTypeDialogOpen] = useState(false);
  const setInternalWorkTypeDialogOpenFalse = useCallWithFalse(setInternalWorkTypeDialogOpen);
  const [cultureDialogOpen, setCultureDialogOpen] = useState(false);
  const setCultureDialogOpenFalse = useCallWithFalse(setCultureDialogOpen);

  const handleRequestAddTask = useCallback(
    (
      userUrl: UserUrl,
      fromTime: Date,
      duration: number | null,
      mousePosition: {left: number; top: number},
      clearDragStartPointEndPoint: () => void,
    ) => {
      setTaskCreation({duration, fromTime, userUrl});
      setTaskCreationPopup({
        anchorPoint: mousePosition,
        clearDragStartPointEndPoint,
      });
    },
    [],
  );

  const role = props.currentRole;
  const userIsManager = !!role && role.manager;

  const handleCreateCultureTask = useCallback(() => {
    handleTaskCreationPopupClose();
    setCultureDialogOpen(true);
  }, [handleTaskCreationPopupClose]);

  const handleCreateInternalTask = useCallback(() => {
    handleTaskCreationPopupClose();
    setInternalWorkTypeDialogOpen(true);
  }, [handleTaskCreationPopupClose]);

  const employeeGroupLookup = useSelector(getEmployeeGroupLookup);
  const userLookup = useSelector(getUserLookup);
  const dispatch = useDispatch();

  const handleInternalWorkTypeDialogOk = useCallback(
    (workTypeURL: WorkTypeUrl) => {
      setInternalWorkTypeDialogOpen(false);
      if (!taskCreation) {
        return;
      }
      const id = uuid();
      const url = instanceURL("task", id);
      const userProfile = userUserProfileLookup(taskCreation.userUrl);
      const user = userLookup(taskCreation.userUrl);
      const employeeGroupURL = userProfile && userProfile.employeeGroup;
      const employeeGroup = employeeGroupURL && employeeGroupLookup(employeeGroupURL);
      const defaultDepartment =
        (user && computeDepartment(userProfile || null, employeeGroup || null, customerSettings)) ||
        null;

      const newTask: Task = {
        ...emptyTask,
        createdBy: taskCreation.userUrl,
        date: dateToString(taskCreation.fromTime),
        department: defaultDepartment || "",
        id,
        machineOperator: taskCreation.userUrl,
        minutesExpectedTotalTaskDuration: taskCreation.duration
          ? _.round(taskCreation.duration)
          : null,
        time: taskCreation.fromTime ? format(taskCreation.fromTime, "HH:mm") : null,
        url,
        workType: workTypeURL,
      };

      dispatch(actions.create(newTask));
      setTaskCreation(null);
      window.setTimeout(() => {
        dispatch(actions.go("/internalTask/:id", {id}));
      }, 0);
    },
    [
      customerSettings,
      dispatch,
      employeeGroupLookup,
      taskCreation,
      userLookup,
      userUserProfileLookup,
    ],
  );

  const handleTaskCreationOk = useCallback(
    (data: {
      contact: ContactUrl | null;
      culture?: CultureUrl;
      customer?: CustomerUrl;
      department: string | null;
      fields: readonly LocationUrl[];
      machines: readonly {
        readonly machine: MachineUrl;
        readonly priceGroup: PriceGroupUrl | null;
      }[];
      priceGroup: PriceGroupUrl | null;
      project: ProjectUrl | null;
      workPlace: LocationUrl | null;
      workType: WorkTypeUrl | null;
    }): void => {
      const {
        contact,
        culture,
        customer,
        department,
        fields,
        machines,
        priceGroup,
        project,
        workPlace,
        workType,
      } = data;

      if (!taskCreation || (!customer && !culture)) {
        return;
      }

      const machineuseSet = machines.map(
        ({machine, priceGroup: machinePriceGroup}): MachineUse => ({
          machine,
          priceGroup: machinePriceGroup,
          transporter: false,
        }),
      );
      const fielduseSet = fields
        .map(locationLookup)
        .filter(notUndefined)
        .map(
          (field): FieldUse => ({
            fieldAreaHa: field.fieldAreaHa,
            fieldCrop: field.fieldCrop,
            geojson: field.geojson,
            notes: "",
            relatedField: field.url,
          }),
        );

      const date = dateToString(taskCreation.fromTime);
      const newOrderPart: Writable<Partial<Order>> = {
        contact,
        created: new Date().toISOString(),
        createdBy: currentUserUrl,
        date,
        durationDays: 1,
        relatedWorkplace: workPlace,
      };
      if (culture) {
        newOrderPart.culture = culture;
      }
      if (customer) {
        newOrderPart.customer = customer;
      }
      const newOrder: Writable<Order> = createOrder(newOrderPart);

      if (
        customerSettings.enableOrderReferenceNumber &&
        customerSettings.autoFillReferenceNumberWithCustomerAccount
      ) {
        const customerInstance = customer ? customerLookup(customer) : null;
        const referenceNumber = (customerInstance && customerInstance.c5_account) || "";
        newOrder.referenceNumber = referenceNumber;
      }
      dispatch(actions.create(newOrder));

      const taskID = uuid();
      const taskURL = instanceURL("task", taskID);
      const newTask: Writable<Task> = {
        ...emptyTask,
        created: new Date().toISOString(), // for local ordering until set by server
        createdBy: currentUserUrl,
        date,
        department: department || "",
        fielduseSet,
        id: taskID,
        machineOperator: taskCreation.userUrl,
        minutesExpectedTotalTaskDuration: taskCreation.duration
          ? _.round(taskCreation.duration)
          : null,
        order: newOrder.url,
        priceGroup,
        priority: null,
        project,
        relatedWorkplace: workPlace,
        time: format(taskCreation.fromTime, "HH:mm"),
        url: taskURL,
        workType,
      };
      if (machineuseSet) {
        newTask.machineuseSet = machineuseSet;
      }

      dispatch(actions.create(newTask));
      setTaskCreation(null);
      window.setTimeout(() => {
        dispatch(
          actions.go("/order/:id/:taskID", {
            id: urlToId(newOrder.url),
            taskID,
          }),
        );
      }, 0);
    },
    [
      currentUserUrl,
      customerLookup,
      customerSettings.autoFillReferenceNumberWithCustomerAccount,
      customerSettings.enableOrderReferenceNumber,
      dispatch,
      locationLookup,
      taskCreation,
    ],
  );

  const [taskCreationState, taskCreationSend] = useMachine(customerTaskCreationStateMachine, {
    actions: {
      signalDone: (context, _event) => {
        const {
          contact,
          customer,
          department,
          fields,
          location,
          machines,
          priceGroup,
          project,
          workType,
        } = context;
        handleTaskCreationOk({
          contact,
          customer: customer as CustomerUrl,
          department,
          fields,
          machines,
          priceGroup,
          project,
          workPlace: location,
          workType,
        });
      },
    },
  });

  const handleCreateCustomerTask = useCallback(() => {
    handleTaskCreationPopupClose();
    if (!taskCreation) {
      return;
    }
    const userProfile = userUserProfileLookup(taskCreation.userUrl);
    const user = userLookup(taskCreation.userUrl);
    if (!customerSettings.customerTaskCreationWizard) {
      setCustomerDialogOpen(true);
    } else {
      const employeeGroupURL = userProfile && userProfile.employeeGroup;

      const employeeGroup = employeeGroupURL && employeeGroupLookup(employeeGroupURL);
      const defaultDepartment =
        (user && computeDepartment(userProfile || null, employeeGroup || null, customerSettings)) ||
        null;
      taskCreationSend({
        customer: null,
        department: defaultDepartment,
        departmentsEnabled: customerSettings.enableExternalTaskDepartmentField,
        fields: [],
        projectsEnabled: customerSettings.enableProjects,
        skipMachineChoice: false,
        type: "START",
        workTypesEnabled: !customerSettings.noExternalTaskWorkType,
      });
      return;
    }
  }, [
    customerSettings,
    employeeGroupLookup,
    handleTaskCreationPopupClose,
    taskCreation,
    taskCreationSend,
    userLookup,
    userUserProfileLookup,
  ]);

  const handleCustomerDialogOk = useCallback(
    (customerUrl: CustomerUrl) => {
      setCustomerDialogOpen(false);
      handleTaskCreationOk({
        contact: null,
        customer: customerUrl,
        department: null,
        fields: [],
        machines: [],
        priceGroup: null,
        project: null,
        workPlace: null,
        workType: null,
      });
    },
    [handleTaskCreationOk],
  );
  const handleCultureDialogOk = useCallback(
    (cultureUrl: CultureUrl) => {
      setCultureDialogOpen(false);
      handleTaskCreationOk({
        contact: null,
        culture: cultureUrl,
        department: null,
        fields: [],
        machines: [],
        priceGroup: null,
        project: null,
        workPlace: null,
        workType: null,
      });
    },
    [handleTaskCreationOk],
  );

  const getActiveCalendarWorkHours = (userURL: string, date: string): CalendarWorkHours | null => {
    const calendarWorkHoursCandidateSeq = calendarWorkHoursArray.filter(
      (c) => c.user === userURL && c.fromDate <= date,
    );
    return _.maxBy(calendarWorkHoursCandidateSeq, (c) => c.fromDate) || null;
  };

  const userColumns: React.JSX.Element[] = [];
  userURLs.forEach((userURL) => {
    const profile = userUserProfileLookup(userURL);
    let normalFromTimestamp: Date | undefined;
    let normalToTimestamp: Date | undefined;
    let userTasksFromOverflow:
      | readonly {overflowMinutes: number; task: TaskWithRelations}[]
      | undefined;
    let punchInOutPeriods: readonly PunchWorkPeriod[] | undefined;
    if (dateFromProps && props.customerSettings.useCalendarWorkHours && userIsManager) {
      const calendarWorkHours = getActiveCalendarWorkHours(userURL, dateFromProps);
      if (calendarWorkHours) {
        const {fromHour} = calendarWorkHours;
        const {toHour} = calendarWorkHours;
        if (fromHour && toHour) {
          normalFromTimestamp = dateFromDateAndTime(dateFromProps, fromHour);
          normalToTimestamp = dateFromDateAndTime(dateFromProps, toHour);
          if (tasksFromOverflow) {
            userTasksFromOverflow = tasksFromOverflow
              .filter((overflowTask) => overflowTask.task.machineOperator === userURL)
              .map((overflowTask) => ({
                overflowMinutes: overflowTask.overflowMinutes,
                task: boundResolveTaskRelations(overflowTask.task),
              }));
          }
        }
      }
    } else if (props.customerSettings.usePunchInOut) {
      const employeePunchInOutPeriods = props.punchInOutPeriodsPerEmployee.get(userURL);
      if (employeePunchInOutPeriods) {
        console.assert(fromTimestamp);
        console.assert(toTimestamp);
        const fromTimestampString = (fromTimestamp as ImmutableDate).toISOString();
        const toTimestampString = (toTimestamp as ImmutableDate).toISOString();
        punchInOutPeriods = findOverlappingPunchInOutPeriodsPart(
          employeePunchInOutPeriods,
          fromTimestampString,
          toTimestampString,
        );
      }
    }

    let getUserHalfHoliday: ((date: string) => string | undefined) | undefined;
    let getUserHoliday: ((date: string) => string | undefined) | undefined;
    const holidaysVisible =
      userIsManager || props.customerSettings.holidaysVisibleToMachineOperators;
    if (holidaysVisible) {
      const extraHolidays = profile
        ? getExtraHolidaysForUser(
            props.customerSettings,
            profile,
            extraHolidaysPerRemunerationGroup,
            extraHalfHolidaysPerRemunerationGroup,
          )
        : undefined;

      getUserHalfHoliday = extraHolidays?.getUserHalfHoliday;
      getUserHoliday = extraHolidays?.getUserHoliday;
    }

    const columns = calculateColumnTaskPacking(
      intersectingPlannedTasks,
      intersectingCompletedTasks,
      incompleteTasks,
      userURL,
      boundResolveTaskRelations,
      props.customerSettings.taskOverlapWarningAfterMinutes,
    );

    const daysAbsenceList = intersectingDaysAbsenceList.filter(
      (absence) => absence.user === userURL,
    );
    const hoursAbsenceList = intersectingHoursAbsenceList.filter(
      (absence) => absence.user === userURL,
    );

    let overlapCheckUserIncompleteTasks: TaskWithRelations[] = [];
    let overlapCheckUserCompletedTasks: TaskWithRelations[] = [];
    if (!props.customerSettings.concurrentTasksAllowed) {
      overlapCheckUserIncompleteTasks = intersectingCompletedTasks
        .filter((task) => task.machineOperator === userURL)
        .map(boundResolveTaskRelations);
      overlapCheckUserCompletedTasks = incompleteTasks
        .filter((task) => task.machineOperator === userURL)
        .map(boundResolveTaskRelations)
        .filter((task) => !!task.intervalsInPeriod.length);
    }

    columns.forEach((tasksWithRelations, index) => {
      const userPlannedTasks = tasksWithRelations.filter((task) => task.phase === "planned");
      const userCompletedTasks = tasksWithRelations.filter((task) => task.phase === "complete");
      const userIncompleteTasks = tasksWithRelations.filter((task) => task.phase === "incomplete");
      const columnStyle: React.CSSProperties =
        index === columns.length - 1
          ? {
              borderRight: "1px solid #000",
            }
          : {};
      console.assert(fromTimestamp);
      console.assert(toTimestamp);
      userColumns.push(
        <UserColumn
          anniversaries={userAnniversaryMap ? userAnniversaryMap.get(userURL) : undefined}
          completedTasks={userCompletedTasks}
          currentRole={props.currentRole}
          currentUserURL={props.currentUserURL}
          customerSettings={props.customerSettings}
          dateTransitionMark={dateTransitionMark}
          daysAbsenceList={index === 0 ? daysAbsenceList : []}
          fromTimestamp={fromTimestamp as ImmutableDate}
          getUserHalfHoliday={index === 0 ? getUserHalfHoliday : undefined}
          getUserHoliday={index === 0 ? getUserHoliday : undefined}
          go={props.go}
          holidaysVisible={holidaysVisible}
          hoursAbsenceList={index === 0 ? hoursAbsenceList : []}
          incompleteTasks={userIncompleteTasks}
          key={userURL + index}
          normalFromTimestamp={
            normalFromTimestamp ? new ImmutableDate(normalFromTimestamp) : undefined
          }
          normalToTimestamp={normalToTimestamp ? new ImmutableDate(normalToTimestamp) : undefined}
          now={now.toISOString()}
          onAbsenceClick={props.onAbsenceClick}
          onRequestAddTask={handleRequestAddTask}
          onRequestCloseTaskInfo={props.onRequestCloseTaskInfo}
          onRequestTaskInfo={props.onRequestTaskInfo}
          overlapCheckUserCompletedTasks={overlapCheckUserCompletedTasks}
          overlapCheckUserIncompleteTasks={overlapCheckUserIncompleteTasks}
          plannedTasks={userPlannedTasks}
          punchInOutPeriods={punchInOutPeriods}
          singleDateFocus={singleDateFocus}
          style={columnStyle}
          tasksFromOverflow={userTasksFromOverflow}
          toTimestamp={toTimestamp as ImmutableDate}
          unitLookup={unitLookup}
          userURL={userURL}
        />,
      );
    });
  });
  const hoursColumn = props.hideHoursColumn ? null : (
    <HoursColumn
      dateTransitionMark={dateTransitionMark}
      fromTimestamp={fromTimestamp}
      toTimestamp={toTimestamp}
    />
  );

  let cardWidth = userColumns.length * USER_COLUMN_WIDTH;
  if (!props.hideHoursColumn) {
    cardWidth = cardWidth + HOUR_COLUMN_WIDTH;
  }

  const menuAnchorPositionProp: Partial<MenuProps> = {};
  if (taskCreationPopup?.anchorPoint) {
    menuAnchorPositionProp.anchorPosition = taskCreationPopup.anchorPoint;
  }

  return (
    <div style={{position: "relative", whiteSpace: "nowrap", width: cardWidth}}>
      {fromTimestamp.valueOf() < now.valueOf() && now.valueOf() < toTimestamp.valueOf() ? (
        <NowRuler width={cardWidth} />
      ) : null}
      {hoursColumn}
      {userColumns}
      <Menu
        {...menuAnchorPositionProp}
        anchorReference="anchorPosition"
        onClose={handleTaskCreationPopupClose}
        open={!!taskCreationPopup}
      >
        {customerSettings.adminCanCreateCustomerTask ? (
          <MenuItem onClick={handleCreateCustomerTask}>
            <FormattedMessage defaultMessage="Kundeopgave" />
          </MenuItem>
        ) : null}
        {customerSettings.externalTaskCulture ? (
          <MenuItem onClick={handleCreateCultureTask}>
            <FormattedMessage defaultMessage="Kulturopgave" />
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleCreateInternalTask}>
          <FormattedMessage defaultMessage="Intern opgave" />
        </MenuItem>
      </Menu>
      {customerSettings.customerTaskCreationWizard ? (
        <CustomerTaskCreationDisplay send={taskCreationSend} state={taskCreationState} />
      ) : null}
      <ConnectedCustomerDialog
        onCancel={setCustomerDialogOpenFalse}
        onOk={handleCustomerDialogOk}
        open={customerDialogOpen}
      />
      <ConnectedInternalWorkTypeDialog
        onCancel={setInternalWorkTypeDialogOpenFalse}
        onOk={handleInternalWorkTypeDialogOk}
        open={internalWorkTypeDialogOpen}
      />
      <ConnectedCultureDialog
        onCancel={setCultureDialogOpenFalse}
        onOk={handleCultureDialogOk}
        open={cultureDialogOpen}
      />
    </div>
  );
});

const ConnectedCalendar: React.ComponentType<CalendarOwnProps> = connect<
  CalendarStateProps,
  CalendarDispatchProps,
  CalendarOwnProps,
  AppState
>(
  createStructuredSelector<AppState, CalendarStateProps>({
    calendarWorkHoursArray: getCalendarWorkHoursArray,
    contactLookup: getContactLookup,
    cultureLookup: getCultureLookup,
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    extraHalfHolidaysPerRemunerationGroup: getExtraHalfHolidaysPerRemunerationGroup,
    extraHolidaysPerRemunerationGroup: getExtraHolidaysPerRemunerationGroup,
    locationLookup: getLocationLookup,
    machineLookup: getMachineLookup,
    orderLookup: getOrderLookup,
    priceGroupLookup: getPriceGroupLookup,
    priceItemLookup: getPriceItemLookup,
    productLookup: getProductLookup,
    projectLookup: getProjectLookup,
    punchInOutPeriodsPerEmployee: getPunchInOutPeriodsPerEmployee,
    routePlanLookup: getRoutePlanLookup,
    taskPhotoArray: getTaskPhotoArray,
    timerLookup: getTimerLookup,
    unitLookup: getUnitLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    go: actions.go,
  },
)(Calendar);

export {ConnectedCalendar as Calendar};
